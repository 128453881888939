/* You can add global styles to this file, and also import other style files */
@import './variables.scss';
@import './fonts.scss';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "~@angular/material/prebuilt-themes/pink-bluegrey.css";

body,
html {
  width: 100%;
  height: 100%;
  color: #fff;
  overflow: hidden;
  font-family: 'Gotham-Book';
  background-color: $bg-primary;

  .modal-lg {
    max-width: 900px;
  }
}

/**
helpers
*/
.form-group {
  margin-bottom: 15px;
}
.b-left {
  display: inline-block;
  border-left: 4px solid #fff;
  padding-left: 5px;
}

.base-64-image-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.modal-billing div .modal-content {
  width: 125% !important;
  left: -10% !important;
}

.base-64-image {
  display: inline-block;
  width: 350px;
  border: 5px solid #00be9a;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 10px;
  padding: 0;

  + button {
    margin-top: 10px;
    max-width: 200px;
  }
}

.marger {
  margin-top: 20px;
  margin-bottom: 10px;
}

.modal-content {
  background-color: $bg-primary;
}

.modal-header {
  button.close {
    color: $white;
  }
}

.card {
  color: #eee;
  padding: 20px;
  background-color: $bg-secondary;
}

.table {
  border-radius: 10px;
  background-color: $bg-secondary;

  thead th {
    border-top: 0 !important;
  }

  td,
  th {
    background-color: transparent;
  }
}

.elevated {
  margin-right: 10px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, .3);
}

.action {
  text-align: right;
  position: relative;
  padding-bottom: 10px;
}

div.action {
  width: 150px;
}

td.action,
th.action {
  width: 80px;
}

.btn {
  position: relative;
  padding: 5px 25px;
  color: #fff;

  &:hover,
  &:disabled {
    color: #fff;
  }

  &.btn-icon-right {
    padding: 5px 40px 5px 20px;

    i {
      top: 50%;
      right: 10px;
      font-size: 16px;
      position: absolute;
      transform: translateY(-50%);
    }
  }

  &.btn-icon-left {
    padding: 5px 20px 5px 35px;

    i {
      top: 50%;
      left: 10px;
      font-size: 16px;
      position: absolute;
      transform: translateY(-50%);
    }
  }

  &.btn-lg {
    border-radius: 20px;
  }

  &.btn-icon {
    padding: 0;
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    line-height: 25px;
    font-size: 13px;
    font-weight: 100;
    color: $white;
    position: relative;
    border-color: $bg-primary;
    background-color: $bg-primary;

    i {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }

    &.btn-primary {
      color: $primary;
    }

    &.btn-danger {
      color: $danger;
    }
  }
}

.form-group.has-error {

  input,
  textarea {
    border: 1px solid red;
  }
}

.toast-container {
  z-index: 99999;
}

pre {
  padding: 10px;
  border: 1px solid #999;
  background-color: #ddd;

}

h1 {
  font-size: 140%;
}

h2 {
  font-size: 120%;
}

h3 {
  font-size: 100%;
}

h1,
h2,
h3 {
  color: $primary;
  position: relative;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-family: 'Gotham-Medium';

  display: flex;
  align-items: center;

  p {
    flex: 1;
    top: 8px;
    position: relative;
  }

  span {
    color: #fff;
    width: 40px;
    height: 40px;
    font-size: 17px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
    background-color: $primary;
  }
}

.icon-group {
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
}

.custom-control-label {
  cursor: pointer;
}

label {
  color: $primary;
}

.gradient-row {
  font-weight: 600;
  margin: 15px 0;

  div:not(.icon-group) {
    align-items: center;
  }

  &.primary {
    span {
      color: $primary;
    }

    background: $linear-primary;
  }

  &.danger {
    span {
      color: $light-red;
    }

    background: $linear-danger;
  }
}

.row.title {
  color: $primary;
  font-size: 1.5rem;
  padding-top: 20px;
  padding-left: 25px;
}

.ng2-tag-input {
  border-bottom: 1px solid #fff !important;

  &.ng2-tag-input--focused {
    border-bottom: 1px solid $primary !important;
  }

  .ng2-tags-container {
    tag {
      * {
        color: #fff;
        border: 0;
        background-color: $primary;
      }
    }

    tag-input-form {
      input {
        border-radius: 15px;
        color: #fff !important;
        border: 1px solid #fff !important;
        background-color: $bg-secondary;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 140%;
  }

  h2 {
    font-size: 120%;
  }

  h3 {
    font-size: 100%;
  }
}

@media screen and (max-width: 576px) {
  body {
    font-size: 80%;
  }

  h1 {
    font-size: 120%;
  }

  h2 {
    font-size: 100%;
  }

  h3 {
    font-size: 80%;
  }
}

// Estilização datepicker
.mat-focused .mat-form-field-label {
  /*change color of label*/
  color: white !important;
 }

.mat-form-field-underline {
  /*change color of underline*/
  background-color: white !important;
 }

.mat-form-field-ripple {
  background-color: #2F3348 !important;;
 }

.mat-form-field-label {
  color: #2F3348 !important;
 }
.mat-calendar {
  background-color: #20c997;
}

.mat-datepicker-toggle {
  color: #20c997 !important;
 }
.mat-calendar-body-selected {
  background-color: transparent !important;
  border: 1px solid white !important;
}

.mat-mdc-text-field-wrapper {
  background-color: #2F3348 !important;
}

.time-container {
  background-color: #20c997;
}

.mat-datepicker-content-touch-with-time span {
  font-size: 80% !important;
  color: white;
}

.mdc-button__label span {
  font-size: 120% !important;
}

.mat-calendar-body-cell-content {
  border: none !important;
}

.mat-calendar-body-cell-content:hover {
  background-color: rgba(0, 0, 0, 0.287);
}

.mat-calendar-body-selected {
  border: 1px solid white !important;
}

.mat-calendar-arrow {
  fill: white !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: var(--bs-primary);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--bs-gray);
}
