@font-face {
  font-family: 'Gotham-Black';
  src: url('/assets/fonts/Gotham-Black.otf');
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('/assets/fonts/Gotham-Book.otf');
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url('/assets/fonts/Gotham-Medium.otf');
}

@font-face {
  font-family: 'Gotham-Thin';
  src: url('/assets/fonts/Gotham-Thin.otf');
}
