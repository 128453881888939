$red: #FC5D92;
$blue: #1777F1;
$gray: #6c757d;
$cyan: #17a2b8;
$white: #ffffff;

$light-red1: #F77171;
$light-red: #ef9a9a ;
$light-blue: #5FABFB;
$light-gray: #9aa2a8;
$light-cyan: #6ac3d1;

$bg-primary: #1E1E2D;
$bg-secondary: #2F3348;

$primary: #00BE9A;
$danger: #FD5B8D;
$warning: #ffe600;
$info: #1777F1;
$beta: #f80084;

$linear-primary: linear-gradient(to right, #00A099, #00EEC1);
$linear-danger: linear-gradient(to right, #FD5D93, #FD5175);
$linear-primary-vertical: linear-gradient(to bottom, #00A099, #00EEC1);
$linear-primary-vertical-menu: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
$linear-global-vertical: linear-gradient(to bottom, $bg-primary, $bg-secondary);
$linear-global-vertical-menu: linear-gradient(to right, #00A099, #00EEC1);

$border-radius: 15px;

$input-bg: $bg-secondary;
$input-color: $white;
$table-color: $white;
$input-disabled-bg: $bg-primary;

$input-placeholder-color: $white;
